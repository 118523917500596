import { ApplicationBuildId, UUID } from "../hooks/types";

export enum DeviceType {
  Desktop = "pc-desktop-client",
  Standalone = "vr-standalone",
  Browser = "browser",
  Remote = "pc-remote-client",
  Unknown = "unknown",
}

type DeviceVendorName = "htc" | "oculus" | "pico" | "meta";

interface ClientDeviceBase {
  identifier: string;
  isOnline: boolean;
  name: string;
  type: DeviceType;
  version?: string;
  targetVersion?: string;
  isOutdated?: boolean;
  modelName?: string;
  vendorName?: DeviceVendorName;
}

export interface ClientDeviceData extends ClientDeviceBase {
  lastSeenDateTime: string;
}

export enum SessionState {
  RequestingRenderMachine = "RequestingRenderMachine",
  RenderMachineReady = "RenderMachineReady",
  RequestedLogin = "RequestedLogin",
  RequestedAppInstallation = "RequestedAppInstallation",
  AppInstalled = "AppInstalled",
  RequestedLaunchedApp = "RequestedLaunchedApp",
  LaunchedApp = "LaunchedApp",
  RequestedTermination = "RequestedTermination",
  Terminated = "Terminated",
}

export interface SessionData {
  id?: string;
  // FIXME this should be an enum of available types
  state: number;
  appLaunched?: boolean;
  progress?: number;
  message?: string;
  expectedWaitTimeSec?: number;
  deviceIdentifier?: string;
  deviceVendorName?: string | null;
  deviceModelName?: string | null;
  applicationBuildId?: ApplicationBuildId;
  type: SessionType;
  launchArguments?: string;
  extraLaunchArguments?: string;
  ipAddress?: string;
  renderRegion?: string;
  renderCloudProvider?: string;
  organizationId?: string;
  vmSize?: string;
  vmImage?: string;
  cloudXREncryption?: boolean;
  debugModeEnabled: boolean;
  issues: {
    hasBadLatency: boolean;
    hasPackageLoss: boolean;
    hasHighJitter: boolean;
    hasNotEnoughBandwidth: boolean;
  };
  error?: ErrorMessage;
}

export interface NotificationMessage {
  alert: boolean;
  notificationType:
    | "MaxSoftSessionRunTime"
    | "MaxSessionRunTime"
    | "LargeDistanceToRenderServer"
    | "HighLatency"
    | "SessionInactivityTimeoutTime";
  level: "Success" | "Warning" | "Error" | "Information";
  params?: { [key: string]: string };
}

export enum SessionType {
  CloudRenderedNonVR = "browser",
  CloudRenderedVR = "cloud",
  LocallyRenderedWindows = "desktop",
  LocallyRenderedStandalone = "standalone",
  Unknown = "unknown",
}

type InstalledApp = { id: number; identity: string };

export interface ClientDevice extends ClientDeviceBase {
  lastSeenDateTime: number;
  installedApps: InstalledApp[];
  wireguardPublicKey?: string | null;
}

export interface DefaultSessionOverviewMessage {
  id: string;
  state: SessionState;
  appId: string;
  launchArguments?: string;
  extraLaunchArguments?: string;
  appTargetPlatform: string;
  deviceIdentifier: string;
  deviceTargetPlatform: DeviceType;
  appLaunched: boolean;
}

export interface CloudRenderedSessionOverviewMessage
  extends DefaultSessionOverviewMessage {
  ipAddress?: string;
  renderRegion?: string;
  renderCloudProvider?: string;
  vmSize?: string;
  debugModeEnabled: boolean;
  vmImage?: string;
}

export interface InstalledAppsMessage {
  apps: { id: string; identity: string }[];
  deviceIdentifier: string;
}

export interface ClientIdentificationMessage {
  identifier: string;
  name: string;
  type: DeviceType;
  version: string;
}

export interface ClientIdentificationListMessage {
  clients: ClientDeviceData[];
}

export interface SessionCreatedMessage {
  id: string;
  deviceIdentifier: string;
  appId: string;
  launchArguments?: string;
  extraLaunchArguments?: string;
  appTargetPlatform: string;
  deviceTargetPlatform: DeviceType;
}

export interface CloudRenderedSessionCreatedMessage
  extends SessionCreatedMessage {
  renderRegion: string;
  renderCloudProvider: string;
  vmImage?: string;
}

export interface DefaultSessionStartupProgressMessage {
  sessionId: string;
  progress: number;
  message: string;
  sessionState: string;
  appId: string;
  launchArguments: string;
  extraLaunchArguments: string;
  renderRegion: string;
}

export interface CloudRenderedSessionStartupProgressMessage
  extends DefaultSessionStartupProgressMessage {
  renderCloudProvider: string;
  expectedWaitTimeSec: number;
  vmSize: string;
  cloudXREncryption: boolean;
}

export type SessionStartupProgressMessage =
  | DefaultSessionStartupProgressMessage
  | CloudRenderedSessionStartupProgressMessage;

export interface AppLaunchedMessage {
  sessionId: string;
  deviceIdentifier: string;
}

interface DefaultAppInstalledMessage {
  sessionId: string;
  appId: string;
  deviceIdentifier: string;
}

interface CloudRenderedAppInstalledMessage extends DefaultAppInstalledMessage {
  ipAddress: string;
}

export type AppInstalledMessage =
  | DefaultAppInstalledMessage
  | CloudRenderedAppInstalledMessage;

export interface RequestSessionMessage {
  appId: string;
  deviceIdentifier: string;
  extraLaunchArguments?: string;
  organizationId: string;
  renderRegion?: string;
  vmSize?: string;
  vmImage?: string;
  forceColdVm: boolean;
  cloudXREncryption?: boolean;
  debugModeEnabled: boolean;
  virtualMachineId?: string;
}

export interface ErrorMessage {
  sessionId?: string;
  message?: string;
  knownSessionError?: string;
  knownSessionErrorCode?: KnownErrorCodes;
}

export type SessionOverviewMessage =
  | Record<string, never>
  | DefaultSessionOverviewMessage
  | CloudRenderedSessionOverviewMessage;

export interface Latency {
  regionName: string;
  latencyMs: number;
}

export interface ReportedIssue {
  id: UUID;
  issueCode: number;
  sourceDeviceIdentifier: string;
  message: string;
  params?: { [key: string]: string };
}

export interface IssueHandledMessage {
  id: UUID;
  issueCode: number;
  message: string;
  params?: { [key: string]: string };
}

export enum KnownIssueCodes {
  BadLatency = 2001,
  HighJitter = 2002,
  HighPackageLoss = 2003,
  LowBandwidth = 2004,
  OpenUrl = 100200,
  OpenCallbackUrl = 100201,
}

export enum KnownErrorCodes {
  SessionTimeout = 5000,
  NoFixedIpAvailable = 5010,
  NoVmAvailable = 5020,
  CouldNotProvisionVm = 5030,
  ImageNotFound = 5040,
  NoUnmanagedNodeAvailable = 5050,
  AuthTokenNotMatching = 5060,
}
